import React from 'react';
import { css } from 'aphrodite';
import _, { isEqual, cloneDeep } from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import WithLayoutProps from '~/src/hoc/WithLayoutProps';
import Card, { CardTitle } from '~/src/components/Card';

import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import {
  COURT_FORM_PLANS,
  COURT_FORM_ANNUAL_PLANS,
  COURT_FORM_MONTHLY_PLANS,
  DOCUMENT_AUTOMATION_PLANS,
} from '~/src/utils/constants';
import styles from '../styles';
import { Typography } from '@mui/material';
import { CreateNewSubscription } from '../CreateNewSubscription';
import { NoSubscriptionNeeded } from '../NoSubscriptionNeeded';
import { CancelCard } from '../CancelCard';
import { SubscriptionDetails } from './SubscriptionDetails';
import {
  SEATS_MONTHLY_PRICE,
  SEATS_YEARLY_PRICE,
} from '~/src/entities/subscription';
import { useCurrentOrg } from '~/src/entities/user';

class SettingsSubscription extends React.Component {
  constructor(props) {
    super(props);

    // Generate state.courtFormPlans based on props.subscription.plan.name
    let courtFormPlans = [];
    const {
      subscription: { plan, quantity, ccId },
    } = props;

    const WORD_DOCUMENT_AUTOMATION_NAME = 'WORD DOCUMENT AUTOMATION';

    for (let i = 0; i < COURT_FORM_MONTHLY_PLANS.length; i++) {
      courtFormPlans.push(plan.id.includes(COURT_FORM_MONTHLY_PLANS[i].id));
    }

    if (!courtFormPlans.includes(true)) {
      courtFormPlans = [];
      for (let i = 0; i < COURT_FORM_ANNUAL_PLANS.length; i++) {
        courtFormPlans.push(plan.id.includes(COURT_FORM_ANNUAL_PLANS[i].id));
      }
    }

    this.state = {
      nextInvoice: 'Calculating...',
      courtFormPlans,
      documentAutomationPlans: plan.name.includes(
        WORD_DOCUMENT_AUTOMATION_NAME,
      ),
      currentState: {
        courtFormPlans: [...courtFormPlans],
        documentAutomationPlans: plan.name.includes(
          WORD_DOCUMENT_AUTOMATION_NAME,
        ),
      },
      cycle: plan.interval === 'month' ? 'monthly' : 'annual',
      seats: quantity,
      cardToken: ccId,
      pendingRequest: false,
    };
  }

  async componentDidMount() {
    this.fetchInvoice();
  }

  fetchInvoice = async () => {
    const { store } = this.props;
    const { cycle, seats } = this.state;

    this.setState({ pendingRequest: true });
    const nextInvoice =
      await store.user.currentOrganization.fetchUpcomingInvoice(
        [],
        [],
        [],
        cycle,
        seats,
        '',
      );

    if (nextInvoice) {
      const { total } = nextInvoice;
      this.setState({ nextInvoice: total / 100 });
    }
    this.setState({ pendingRequest: false });
  };

  onBillingCycleChange = async () => {
    const { subscription } = this.props;
    const seats = subscription.quantity;
    const cardToken = subscription.ccId;
    const cycle = subscription.plan.interval === 'month' ? 'monthly' : 'annual';
    const newCycle = cycle === 'monthly' ? 'annual' : 'monthly';

    this.props.hideModal();

    this.setState({ pendingRequest: true });
    const res =
      await this.props.store.user.currentOrganization.updateSubscription({
        add_plans: [],
        remove_plans: [],
        'card-token': cardToken,
        seat: seats,
        coupon: '',
        trial_period_days: '',
        interval_change: true,
        duration: newCycle,
      });
    this.setState({ pendingRequest: false });

    if (res) {
      this.setState({ cycle: newCycle }, this.fetchInvoice);
    }
  };

  onBillingCycleCancel = (form) => {
    const toggleStates = {
      annual: 'monthly',
      monthly: 'annual',
    };
    form.fields.cycle.value = toggleStates[form.fields.cycle.value];
    this.props.hideModal();
  };

  handlePlanChange = async (form) => {
    const selectedPlan =
      form && form.fields && form.fields.plan ? form.fields.plan.value : null;
    const newState = cloneDeep(this.state);

    const currentState = {
      courtFormPlans: this.state.courtFormPlans,
      documentAutomationPlans: this.state.documentAutomationPlans,
    };
    if (selectedPlan) {
      const { index, selected } = selectedPlan;
      if (index) {
        // Add to document automation plans
        newState.documentAutomationPlans = selected[0];
      } else {
        // Add to court form plans
        newState.courtFormPlans = selected;
      }
    }
    const cycle = form.fields.cycle.value;
    const { nextInvoice, seats, cardToken } = this.state;
    const { courtFormPlans, documentAutomationPlans } = newState;
    if (
      courtFormPlans.filter((item) => item).length === 0 &&
      !documentAutomationPlans
    ) {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'You should select at least 1 plan!',
      });
      return;
    }

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];

    const currentCourtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => currentState.courtFormPlans[id])
      .map((plan) => plan.id);
    const currentDocumentAutomationPlanIds =
      currentState.documentAutomationPlans
        ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
        : [];
    newState.cycle = cycle;

    if (newState.cycle !== this.state.cycle) {
      let newPlanPrice = 0;
      // Calculate the next plan
      if (newState.cycle === 'annual') {
        const pricePlans =
          COURT_FORM_MONTHLY_PLANS.filter((plan, id) => courtFormPlans[id]).map(
            (plan) => plan.price,
          ) || [];
        const documentAutomationAnnualPlans = DOCUMENT_AUTOMATION_PLANS.annual;
        newPlanPrice = pricePlans.reduce((acc, item) => acc + item, 0) * 10;
        newPlanPrice += documentAutomationPlans
          ? documentAutomationAnnualPlans[0].price
          : 0;
        newPlanPrice += seats * SEATS_YEARLY_PRICE;
      } else {
        const pricePlans =
          COURT_FORM_ANNUAL_PLANS.filter((plan, id) => courtFormPlans[id]).map(
            (plan) => plan.price,
          ) || [];
        const documentAutomationMonthlyPlans =
          DOCUMENT_AUTOMATION_PLANS.monthly;
        newPlanPrice = pricePlans.reduce((acc, item) => acc + item, 0) / 10;
        newPlanPrice += documentAutomationPlans
          ? documentAutomationMonthlyPlans[0].price
          : 0;
        newPlanPrice += seats * SEATS_MONTHLY_PRICE;
      }
      // Show confirmation form to change billing cycle
      this.props.showModal(LAYOUT_MODAL_TYPES.confirm, {
        title: `Change billing cycle to ${cycle}`,
        message: (
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between font-medium text-sm mb-2">
              <span>Current plan</span>
              <span>
                ${nextInvoice} / {cycle === 'monthly' ? 'year' : 'mo'}
              </span>
            </div>
            <div className="flex flex-row justify-between font-medium text-sm">
              <span>New plan</span>
              <span>
                ${newPlanPrice} / {cycle === 'monthly' ? 'mo' : 'year'}
              </span>
            </div>
          </div>
        ),
        onConfirm: this.onBillingCycleChange,
        onCancel: () => {
          this.onBillingCycleCancel(form);
        },
        primaryActionTitle: 'Confirm',
      });
      return;
    }

    if (!isEqual(newState, this.state)) {
      // Update court form plans or document automation plans
      this.setState({ pendingRequest: true });

      const res =
        await this.props.store.user.currentOrganization.updateSubscription({
          add_plans: _.concat(
            _.difference(courtFormPlanIds, currentCourtFormPlanIds),
            _.difference(
              documentAutomationPlanIds,
              currentDocumentAutomationPlanIds,
            ),
          ),
          remove_plans: _.concat(
            _.difference(currentCourtFormPlanIds, courtFormPlanIds),
            _.difference(
              currentDocumentAutomationPlanIds,
              documentAutomationPlanIds,
            ),
          ),
          'card-token': cardToken,
          seat: seats,
          coupon: '',
          trial_period_days: '',
          interval_change: false,
          duration: cycle,
        });
      this.setState({ pendingRequest: false });

      if (res) {
        this.setState({ ...newState, seats, currentState }, this.fetchInvoice);
      }
    }
  };

  handleUpdateSeats = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.subscriptionUpdate);
  };

  render() {
    const { onCreateNewSubscription, subscription, isClioFree } = this.props;

    const {
      nextInvoice,
      courtFormPlans,
      documentAutomationPlans,
      cycle,
      pendingRequest,
    } = this.state;

    const noSubscription = isClioFree ? (
      <CreateNewSubscription
        onCreateNewSubscription={onCreateNewSubscription}
      />
    ) : (
      <NoSubscriptionNeeded />
    );

    return (
      <div className={css(styles.settingsCardContainer)}>
        <Card elevate>
          {subscription ? (
            <SubscriptionDetails
              pendingRequest={pendingRequest}
              subscription={subscription}
              onUpdateSeats={this.handleUpdateSeats}
              onUpdatePaymentMethod={this.handleUpdatePaymentMethod}
              onRenewSubscription={this.handleRenewSubscription}
              nextInvoice={nextInvoice}
              cycle={cycle}
              handlePlanChange={this.handlePlanChange}
              courtFormPlans={courtFormPlans}
              documentAutomationPlans={documentAutomationPlans}
            />
          ) : (
            noSubscription
          )}
        </Card>
        <div className={css(styles.settingsOuterButtonContainer)}>
          {subscription && !subscription.canceledAt ? (
            <CancelCard
              endDate={moment(subscription.currentPeriodEnd).format(
                'MMMM DD, YYYY',
              )}
              onCancelSubscription={this.handleCancelSubscription}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const ObservedSubscriptionSettings = observer(SettingsSubscription);

const SubscriptionWrapper = (props) => {
  const { org } = useCurrentOrg();
  if (!org.admin) {
    return (
      <div className={css(styles.settingsCardContainer)}>
        <Card elevate>
          <CardTitle bold>Subscription</CardTitle>
          <Typography>
            Please contact the organization&apos;s administrator to have them
            update the subscription information.
          </Typography>
        </Card>
      </div>
    );
  }

  return <ObservedSubscriptionSettings {...props} />;
};

export const SettingsSubscriptionV2 = inject((store) => store)(
  WithLayoutProps(SubscriptionWrapper),
);
