import { useEffect, useRef, useState } from 'react';
import WebViewer, { type WebViewerInstance } from '@pdftron/webviewer';

export const useWebviewer = ({
  licenseKey,
  path,
}: {
  licenseKey: string;
  path: string;
}) => {
  const [webviewerRef, setWebviewerRef] = useState(
    useRef<HTMLDivElement | null>(null),
  );
  const [webviewerInstance, setWebviewerInstance] =
    useState<WebViewerInstance | null>(null);

  useEffect(() => {
    if (!webviewerRef.current) {
      return;
    }

    void WebViewer(
      {
        licenseKey,
        path,
      },
      webviewerRef.current,
    ).then((instance) => {
      setWebviewerInstance(instance);
    });

    return () => {
      setWebviewerInstance(null);
    };
  }, [licenseKey, path, webviewerRef]);

  return {
    webviewerRef,
    webviewerInstance,
    setWebviewerRef,
  };
};
