import * as React from 'react';
import { UIFlags, FlagSetter, UIFlagsContext } from './uiFlags.context';
import {
  useClioQuestionnairesEnabled,
  useCurrentOrg,
  useCbsIntegrationEnabled,
} from '../user';
import { getDefaultFlagsForMembership, setFlagsForMembership } from './utils';

export const UIFlagsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { org } = useCurrentOrg();
  const membershipId = org?.id;
  const isInternalOrg = !!org?.enable_dev_tools;
  const shouldPersistToLocalStorage = isInternalOrg;
  const [enabled, setEnabled] = React.useState(isInternalOrg);

  const isQuestionnairesEnabled = useClioQuestionnairesEnabled();
  const isCbsIntegrationEnabled = useCbsIntegrationEnabled();

  const [flags, setFlags] = React.useState(() => {
    let initialFlags = { ...UIFlags };

    if (isQuestionnairesEnabled) {
      initialFlags = {
        ...initialFlags,
        questionnaireSelectDocumentsBanner: true,
        deprecatedQuestionnairesTable: false,
        newDocumentSelectionPanel: true,
      };
    }

    if (isCbsIntegrationEnabled) {
      initialFlags = {
        ...initialFlags,
        cbsIntegration: true,
      };
    }

    if (!enabled) return initialFlags;
    return {
      ...initialFlags,
      ...getDefaultFlagsForMembership(membershipId),
    };
  });

  (window as any)['flags'] = () => setEnabled(true);

  React.useEffect(() => {
    if (!shouldPersistToLocalStorage) return;
    setFlagsForMembership(membershipId, flags);
  }, [flags, membershipId, shouldPersistToLocalStorage]);

  const setFlag: FlagSetter = (flag, enabled) =>
    setFlags((existing) => ({ ...existing, [flag]: enabled }));

  return (
    <UIFlagsContext.Provider value={{ enabled, flags, setFlag }}>
      {children}
    </UIFlagsContext.Provider>
  );
};
